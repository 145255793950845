import {
  GetOwnDepartmentsResponse,
  PostChartReverseReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';

import { useAction } from './action';
import { OwnDepartmentReverseReferralsCardPresenter } from './presenter';

import { useState, useEffect } from 'react';

const INITIAL_PAGE = 1;

type Props = {
  departments: GetOwnDepartmentsResponse['departments'];
};

export const OwnDepartmentReverseReferralsCard: React.FC<Props> = (props) => {
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [reverseReferrals, setReverseReferrals] = useState<
    | PostChartReverseReferralsComparisonDepartmentsResponse['reverseReferrals']
    | null
  >(null);

  const { request } = useAction();

  useEffect(() => {
    request((result) => {
      setReverseReferrals(result.reverseReferrals);
    });
  }, []);

  return (
    <OwnDepartmentReverseReferralsCardPresenter
      contents={
        reverseReferrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              reverseReferrals,
              departments: props.departments,
              pagination: {
                currentPage,
                prevPage: () => {
                  setCurrentPage((prevState) => prevState - 1);
                },
                nextPage: () => {
                  setCurrentPage((prevState) => prevState + 1);
                },
              },
            }
      }
    />
  );
};
