import {
  PostChartBothReferralsComparisonResponseItemsKeyEnum,
  PostChartReferralsResponse,
} from '@/src/api/generated';
import {
  BarChart,
  TooltipContentProps,
} from '@/src/components/foundations/Charts/BarChart';
import { BarChartTooltip } from '@/src/components/foundations/Charts/BarChartTooltip';
import { getColor } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;
type BarChartTooltipProps = React.ComponentProps<typeof BarChartTooltip>;

const CHART_KEY = {
  [PostChartBothReferralsComparisonResponseItemsKeyEnum.Referral]: '紹介数',
  [PostChartBothReferralsComparisonResponseItemsKeyEnum.ReverseReferral]:
    '逆紹介数',
} as const;

export const createChartData = (
  borthReferrals: PostChartReferralsResponse['referrals'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const charts = (
    Object.keys(CHART_KEY) as unknown as (keyof typeof CHART_KEY)[]
  ).map((key, index) => ({
    key,
    name: CHART_KEY[key],
    color: getColor(index),
  }));

  return {
    data: borthReferrals.map((b) => {
      return {
        name: b.label,
        stacks: b.items.reduce<Record<string, number>>((res, cur) => {
          if (cur.key) {
            res[cur.key] = cur.value;
          }
          return res;
        }, {}),
      };
    }),
    charts,
    payload: charts.map((c) => ({
      value: c.name,
      color: c.color,
    })),
  };
};

export const createChartTooltipData = (
  data: TooltipContentProps,
): BarChartTooltipProps => {
  if (data.active && data.payload && data.payload.length > 0) {
    const referral = data.payload.find(
      (p) =>
        p.dataKey ===
        PostChartBothReferralsComparisonResponseItemsKeyEnum.Referral,
    );
    const reverseReferral = data.payload.find(
      (p) =>
        p.dataKey ===
        PostChartBothReferralsComparisonResponseItemsKeyEnum.ReverseReferral,
    );

    return {
      title: String(data.label),
      items: [
        data.payload.map((p) => ({
          label: String(p.name),
          value: Number(p.value),
          color: p.color,
        })),
        // NOTE: 紹介と逆紹介のデータがあれば表示
        typeof referral?.value === 'number' &&
        typeof reverseReferral?.value === 'number'
          ? [
              {
                label: '差分',
                value: reverseReferral.value - referral.value,
              },
            ]
          : [],
      ].flat(),
    };
  }

  return {
    title: '',
    items: [],
  };
};
