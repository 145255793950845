import {
  MedicalInstitutionReportItemEnabledAggregationMethodEnum,
  MedicalInstitutionReportItemForAggregation,
  MedicalInstitutionReportItemKey,
  PostMedicalInstitutionReportAggregationResultsRequest,
} from '@/src/api/generated';
import { HalfYear } from '@/src/types/date';
import { toMonthRange } from '@/src/utils/date';

import { State } from '../reducer';
import {
  createAggregationData,
  createFilterConditions,
} from './toRequestParameterCommon';

export const toRequestParameterForHalfYear = (
  halfYears: HalfYear[],
  filters: State['filters'],
): PostMedicalInstitutionReportAggregationResultsRequest => {
  const dataItems = createDataItemsForHalfYearUnit(halfYears, filters);

  return {
    displayItems: [
      // 集計行用の項目
      {
        itemKey: MedicalInstitutionReportItemKey.OneFacilityTypeId,
        key: MedicalInstitutionReportItemKey.OneFacilityTypeId,
      },
      ...dataItems,
    ],
    filterConditions: createFilterConditions(filters),
    aggregationRow: {
      itemKey: MedicalInstitutionReportItemKey.OneFacilityTypeId,
    },
    aggregationData: createAggregationData(dataItems),
  };
};

const createDataItemsForHalfYearUnit = (
  halfYears: HalfYear[],
  filters: State['filters'],
): MedicalInstitutionReportItemForAggregation[] =>
  halfYears.flatMap((halfYear) =>
    [
      MedicalInstitutionReportItemKey.ReferralCount,
      MedicalInstitutionReportItemKey.ReferralAdmissionCount,
      MedicalInstitutionReportItemKey.ReferralOperationCount,
    ].map(
      (key): MedicalInstitutionReportItemForAggregation => ({
        itemKey: toItemKey(halfYear, key),
        key,
        aggregationMethod:
          MedicalInstitutionReportItemEnabledAggregationMethodEnum.Sum,
        aggregationConditions: {
          startMonth: toMonthRange(halfYear).startMonth,
          endMonth: toMonthRange(halfYear).endMonth,
          ...(filters.ownDepartmentIds.length >= 1
            ? { ownDepartmentIds: filters.ownDepartmentIds }
            : {}),
          ...(filters.groupMedicalInstitutionId !== null
            ? {
                tenantMedicalInstitutionIds: [
                  filters.groupMedicalInstitutionId,
                ],
              }
            : {}),
        },
      }),
    ),
  );

const toItemKey = (
  period: HalfYear,
  key: MedicalInstitutionReportItemKey,
): string => `${period}-${key}`;
