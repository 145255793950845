import {
  PostDataAnalysisRankingReferralTotalCountResponse as TotalCountReferralRanking,
  PostDataAnalysisRankingReferralTotalCountRequestSortEnum as TotalCountSearchSort,
} from '@/src/api/generated';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Link } from '@/src/components/foundations/Utils/Link';
import { emptyText } from '@/src/utils/emptyText';
import { toDisplayFormat } from '@/src/utils/runningStatus';

import styles from './styles.module.scss';

import LocationOn from '@mui/icons-material/LocationOn';

type Props = {
  isLoading: boolean;
  ranking: TotalCountReferralRanking | null;
  sort: TotalCountSearchSort;
  onChangeSortType: VoidFunction;
};

type IncreaseCountSortResponse = () => string;

export const RankingTablePresenter: React.FC<Props> = (props) => {
  const increaseCountSortIcon: IncreaseCountSortResponse = () => {
    return props.sort === TotalCountSearchSort.Desc
      ? styles.sortIconDesc
      : styles.sortIconAsc;
  };

  return (
    <table className={styles.base}>
      <thead className={styles.head}>
        <tr className={styles.tr}>
          <th className={styles.medicalInstitution}>医療機関名</th>
          <th className={styles.address}>住所</th>
          <th className={styles.referralCount}>
            <button
              onClick={props.onChangeSortType}
              className={styles.referralCountSort}
            >
              <span className={styles.referralCountSortText}>紹介数</span>
              <div className={increaseCountSortIcon()} />
            </button>
          </th>
        </tr>
      </thead>
      <tbody className={styles.body}>
        {((): JSX.Element | JSX.Element[] => {
          if (props.isLoading || !props.ranking) {
            return (
              <tr>
                <td colSpan={4} className={styles.borderTop}>
                  <CenteringContainer className={styles.centeringContainer}>
                    <LoadingCircle />
                  </CenteringContainer>
                </td>
              </tr>
            );
          }

          if (props.ranking.rankings.length === 0) {
            return (
              <tr>
                <td colSpan={4} className={styles.borderTop}>
                  <h2 className={styles.emptyText}>
                    該当する医療機関がありません
                  </h2>
                </td>
              </tr>
            );
          }

          return props.ranking.rankings.map((ranking, index) => (
            <tr className={styles.tr} key={index}>
              <td>
                <div className={styles.medicalInstitutionNameText}>
                  {ranking.medicalInstitution.name ? (
                    <Link
                      href={`/crms/${ranking.medicalInstitution.id}`}
                      isOpenAnotherTab
                    >
                      {toDisplayFormat(
                        ranking.medicalInstitution.runningStatus,
                      )}
                      {ranking.medicalInstitution.name}
                    </Link>
                  ) : (
                    emptyText()
                  )}
                </div>
              </td>
              <td className={styles.addressText}>
                <LocationOn className={styles.locationIcon} />
                <span>
                  {ranking.medicalInstitution.address
                    ? ranking.medicalInstitution.address
                    : emptyText()}
                </span>
              </td>
              <td className={styles.referralCountText}>
                {ranking.referralCount}件
              </td>
            </tr>
          ));
        })()}
      </tbody>
    </table>
  );
};
