import styles from './styles.module.scss';

import clsx from 'clsx';
import Link from 'next/link';

type Props<V> = {
  values: { label: string; value: V; attention?: boolean }[];
  selectedValue: V;
  onClickValue: (v: V) => void;
  pathName?: string;
};

export const Tab: <T extends string>(props: Props<T>) => JSX.Element = (
  props,
) => (
  <div className={styles.base}>
    {props.values?.map((v) => {
      return props.pathName ? (
        <Link
          key={v.value}
          href={{ pathname: props.pathName, query: { tab: v.value } }}
          legacyBehavior
        >
          <button
            className={clsx(
              props.selectedValue === v.value && styles.active,
              v.attention && styles.attention,
            )}
            onClick={() => {
              props.onClickValue(v.value);
            }}
          >
            {v.label}
          </button>
        </Link>
      ) : (
        <button
          className={clsx(
            props.selectedValue === v.value && styles.active,
            v.attention && styles.attention,
          )}
          key={v.value}
          onClick={() => {
            props.onClickValue(v.value);
          }}
        >
          {v.label}
        </button>
      );
    })}
  </div>
);
