import {
  GetOwnDepartmentsResponse,
  PostChartReverseReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';
import { LIST } from '@/src/utils/chartColors';

const SPLIT_UNIT = 12;
const BAR_COUNT = 3;

const COLOR_LIST = LIST.slice(0, BAR_COUNT).reverse();

export const createChartData = (
  reverseReferrals: PostChartReverseReferralsComparisonDepartmentsResponse['reverseReferrals'],
  departments: GetOwnDepartmentsResponse['departments'],
) => {
  const charts = reverseReferrals.map((r, index) => {
    return {
      key: r.label,
      name: r.label,
      color: COLOR_LIST[index % COLOR_LIST.length],
    };
  });
  const payload = charts.map((c) => ({
    value: c.name,
    color: c.color,
  }));

  // NOTE: 最後のラベルをソート基準ラベルとして特定する
  const _sortKeyLable = reverseReferrals.slice(-1)[0].label;

  // NOTE: 診療科目ごとの月次データを作る
  const _data = departments
    .map((department) => {
      return {
        name: department.value,
        stacks: reverseReferrals.reduce<Record<string, number>>((res, cur) => {
          // NOTE: { [対象月]: [対象の診療科目を検索した値] } を作る
          res[`${cur.label}`] =
            cur.items.find((item) => String(item.key) === department.key)
              ?.value ?? 0;
          return res;
        }, {}),
      };
    })
    // NOTE: ソート基準ラベルの値で降順にソート
    .sort((a, b) => {
      return a.stacks[_sortKeyLable] > b.stacks[_sortKeyLable] ? -1 : 1;
    });

  // NOTE: 12個ずつに分割して、二次元配列を作る(ページネーションのため)
  const dividedData = _data.reduce(
    (
      acc: { stacks: Record<string, number>; name: string }[][],
      _,
      i: number,
    ) => {
      return i % SPLIT_UNIT
        ? acc
        : [...acc, ...[_data.slice(i, i + SPLIT_UNIT)]];
    },
    [],
  );

  return {
    charts,
    payload,
    dividedCounts: dividedData.length,
    calcData: (currentPage: number) => {
      if (currentPage < 0 || dividedData.length < currentPage) return [];

      return dividedData[currentPage - 1];
    },
  };
};
