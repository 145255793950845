import { CommunicationType } from '@/src/api/generated';
import { PrefectureSearchTextField } from '@/src/components/blocks/PrefectureSearchTextField';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Input } from '@/src/components/foundations/Forms/Input';
import { MonthPicker } from '@/src/components/foundations/Forms/MonthPicker';
import { SelectBox } from '@/src/components/foundations/Forms/SelectBox';
import { Tab } from '@/src/components/foundations/Navigations/Tab';
import { FormProps } from '@/src/types/form';

import styles from './styles.module.scss';

import Search from '@mui/icons-material/Search';

export type InputMap = {
  isDoctorAttended: string;
  aggregationPeriod: number;
  prefecture: string;
  area: string;
  ownDepartmentIds: string[];
};

const TAB_VALUES: { label: string; value: CommunicationType }[] = [
  { label: '訪問', value: CommunicationType.Visit },
  { label: '来訪', value: CommunicationType.NewVisit },
  { label: '電話', value: CommunicationType.Phone },
  { label: 'メール', value: CommunicationType.Email },
  { label: '郵便物送付', value: CommunicationType.PostalItem },
  { label: 'イベント出席', value: CommunicationType.EventAttendance },
  { label: '紹介受付', value: CommunicationType.ReferralAcceptance },
  { label: '紹介お断り', value: CommunicationType.RefusedReferral },
  { label: 'その他', value: CommunicationType.Other },
];

type OptionProps = Pick<React.ComponentProps<typeof SelectBox>, 'options'>;

type Props = {
  monthPicker: React.ComponentProps<typeof MonthPicker>;
  form: {
    aggregationPeriod: OptionProps;
    prefecture: {
      value: InputMap['prefecture'];
      setValue: (value: NonNullable<InputMap['prefecture']>) => void;
    };
    isDoctorAttended: OptionProps;
    ownDepartmentIds: React.ComponentProps<typeof OwnDepartmentFilterChip>;
  } & FormProps<InputMap>;
  tab: {
    selectedValue: CommunicationType;
    onClickValue: (value: CommunicationType) => void;
  };
  isCommunicationTypeVisit: boolean;
};

export const HeaderPresenter: React.FC<Props> = (props) => (
  <>
    <div className={styles.header}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>個別効果検証</div>
        <div className={styles.form}>
          <div className={styles.prefecture}>
            <PrefectureSearchTextField
              {...props.form.prefecture}
              optionIdPrefix="prefecture"
              mode="normal"
            />
          </div>
        </div>
        <div className={styles.area}>
          <Input placeholder="地域" type="text" {...props.form.area} />
        </div>
        <Button
          color="primary"
          isDisabled={props.form.isDisabled}
          icon={{ body: <Search />, position: 'left' }}
          onClick={props.form.onSubmit}
        >
          検索
        </Button>
      </div>
      <div>
        <OwnDepartmentFilterChip
          {...props.form.ownDepartmentIds}
          size="medium"
        />
      </div>
    </div>
    <div>
      <Tab
        values={TAB_VALUES}
        selectedValue={props.tab.selectedValue}
        onClickValue={props.tab.onClickValue}
      />
      <div className={styles.calcTarget}>
        {props.isCommunicationTypeVisit && (
          <div className={styles.isDoctorAttendedWrapper}>
            <SelectBox {...props.form.isDoctorAttended} />
          </div>
        )}
        <div className={styles.monthPickerWrapper}>
          <span className={styles.monthPickerTitle}>対象月</span>
          <MonthPicker {...props.monthPicker} isInForm />
        </div>
        <div className={styles.aggregationPeriodWrapper}>
          <span className={styles.aggregationPeriodTitle}>集計期間</span>
          <SelectBox {...props.form.aggregationPeriod} />
        </div>
      </div>
    </div>
  </>
);
