import {
  MedicalInstitutionReportDefinitionAggregationData,
  MedicalInstitutionReportDefinitionAggregationDataAggregationMethodEnum,
  MedicalInstitutionReportFilterCondition,
  MedicalInstitutionReportItemForAggregation,
  MedicalInstitutionReportItemKey,
} from '@/src/api/generated';

import { State } from '../reducer';

export const createFilterConditions = (
  filters: State['filters'],
): MedicalInstitutionReportFilterCondition[] =>
  [
    // 次の理由から絞り込み条件には、表示で利用している one_facility_type_id ではなく facility_type_ids を利用していることに注意
    //
    // one_facility_type_id でフィルタをかけると、例えば以下のようなパターンで意図せず絞り込みから漏れてしまうことがある
    // ・「訪問入浴介護」で絞り込みをかけた場合、「訪問介護(id = 3001)」「訪問入浴介護(id = 3002)」の指定がある連携先が漏れてしまう
    // ・これは one_facility_type_id が複数の施設タイプからひとつのみを抽出するため
    filters.facilityTypeIds.length >= 1
      ? ({
          item: { key: MedicalInstitutionReportItemKey.FacilityTypeIds },
          matchingGroups: [{ haveAny: filters.facilityTypeIds }],
        } satisfies MedicalInstitutionReportFilterCondition)
      : undefined,
  ].flatMap((condition) => condition ?? []); // undefined除去のためのflatMap

export const createAggregationData = (
  items: MedicalInstitutionReportItemForAggregation[],
): MedicalInstitutionReportDefinitionAggregationData[] =>
  items.map(
    (item): MedicalInstitutionReportDefinitionAggregationData => ({
      aggregationMethod:
        MedicalInstitutionReportDefinitionAggregationDataAggregationMethodEnum.Sum,
      itemKey: item.itemKey,
    }),
  );
