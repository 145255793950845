import {
  PostDataAnalysisRankingReferralMonthlyResponse as MonthlyReferralRanking,
  PostDataAnalysisRankingReferralMonthlyRequestSortEnum as MonthlySearchSort,
  PostDataAnalysisRankingReferralAnnualResponse as AnnualReferralRanking,
  PostDataAnalysisRankingReferralAnnualRequestSortEnum as AnnualSearchSort,
} from '@/src/api/generated';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Link } from '@/src/components/foundations/Utils/Link';
import { emptyText } from '@/src/utils/emptyText';
import { toDisplayFormat } from '@/src/utils/runningStatus';

import styles from './styles.module.scss';

import LocationOn from '@mui/icons-material/LocationOn';
import clsx from 'clsx';

type Props = {
  mode: 'monthly' | 'annual';
  isLoading: boolean;
  ranking: MonthlyReferralRanking | AnnualReferralRanking | null;
  before: string;
  current: string;
  sort: MonthlySearchSort | AnnualSearchSort;
  onChangeSortType: VoidFunction;
};

type IncreaseCountSortResponse = () => string;
type IncreaseCountResponse = (increaseCount: number) => string;

export const RankingTablePresenter: React.FC<Props> = (props) => {
  const increaseCountSortIcon: IncreaseCountSortResponse = () => {
    return props.sort === (MonthlySearchSort.Desc || AnnualSearchSort.Desc)
      ? styles.sortIconDesc
      : styles.sortIconAsc;
  };
  const increaseCountClassName: IncreaseCountResponse = (increaseCount) => {
    if (increaseCount > 0)
      return clsx(styles.increaseCountText, styles.increaseCountTextPlus);
    if (increaseCount < 0)
      return clsx(styles.increaseCountText, styles.increaseCountTextMinus);
    return clsx(styles.increaseCountText);
  };
  const increaseCountText: IncreaseCountResponse = (increaseCount) => {
    if (increaseCount > 0) return `+${increaseCount}`;
    if (increaseCount < 0) return String(increaseCount);
    return String(increaseCount);
  };

  return (
    <table className={styles.base}>
      <thead className={styles.head}>
        <tr className={styles.tr}>
          <th className={styles.medicalInstitution}>医療機関名</th>
          <th className={styles.referralCountBefore}>
            {props.before}
            {props.mode === 'annual' && '年度'}
          </th>
          <th className={styles.referralCountAfter}>
            {props.current}
            {props.mode === 'annual' && '年度'}
          </th>
          <th className={styles.increaseCount}>
            <button
              onClick={props.onChangeSortType}
              className={styles.increaseCountSort}
            >
              <span className={styles.increaseCountSortText}>増減数</span>
              <div className={increaseCountSortIcon()} />
            </button>
          </th>
        </tr>
      </thead>
      <tbody className={styles.body}>
        {((): JSX.Element | JSX.Element[] => {
          if (props.isLoading || !props.ranking) {
            return (
              <tr>
                <td colSpan={4} className={styles.borderTop}>
                  <CenteringContainer className={styles.centeringContainer}>
                    <LoadingCircle />
                  </CenteringContainer>
                </td>
              </tr>
            );
          }

          if (props.ranking.rankings.length === 0) {
            return (
              <tr>
                <td colSpan={4} className={styles.borderTop}>
                  <h2 className={styles.emptyText}>
                    該当する医療機関がありません
                  </h2>
                </td>
              </tr>
            );
          }

          return props.ranking.rankings.map((ranking, index) => (
            <tr className={styles.tr} key={index}>
              <td>
                <div className={styles.medicalInstitutionNameText}>
                  {ranking.medicalInstitution.name ? (
                    <Link
                      href={`/crms/${ranking.medicalInstitution.id}`}
                      isOpenAnotherTab
                    >
                      {toDisplayFormat(
                        ranking.medicalInstitution.runningStatus,
                      )}
                      {ranking.medicalInstitution.name}
                    </Link>
                  ) : (
                    emptyText()
                  )}
                </div>
                <div className={styles.medicalInstitutionAddress}>
                  <LocationOn className={styles.icon} />
                  <div className={styles.medicalInstitutionAddressText}>
                    {ranking.medicalInstitution.address
                      ? ranking.medicalInstitution.address
                      : emptyText()}
                  </div>
                </div>
              </td>
              <td>{ranking.referralCountBefore}</td>
              <td>{ranking.referralCountAfter}</td>
              <td className={increaseCountClassName(ranking.increaseCount)}>
                {increaseCountText(ranking.increaseCount)}
              </td>
            </tr>
          ));
        })()}
      </tbody>
    </table>
  );
};
