import { useScrim } from '@/src/hooks/useScrim';
import { useShowToast } from '@/src/hooks/useToast';
import { isHalfYear, toHalfYearLabel } from '@/src/utils/date';
import { outputCSV } from '@/src/utils/outputCSV';

import { State } from './reducer';
import { facilityTypeToLabel, itemTypeToLabel, toYearLabel } from './selector';
import { TABLE_ROW_TYPES, ITEM_TYPES, TableData } from './types';

export const useAction = () => {
  const showToast = useShowToast();
  const scrim = useScrim();

  return {
    csvDownload: (
      filename: string,
      data: TableData,
      dateUnit: State['dateUnit'],
    ) => {
      scrim.show('CSVを出力中です');

      const periods = data.total.map(({ period }) => period);

      try {
        outputCSV(
          filename,
          // テーブルヘッダーの1行目
          [
            '',
            ...ITEM_TYPES.flatMap((itemType) =>
              periods.map((period, i): string =>
                i === 0 ? itemTypeToLabel(itemType) : '',
              ),
            ),
          ],
          [
            // テーブルヘッダーの2行目
            [
              '',
              ...ITEM_TYPES.flatMap(() =>
                periods.map((period): string => {
                  return dateUnit === 'halfYear' && isHalfYear(period)
                    ? toHalfYearLabel(period)
                    : toYearLabel(period);
                }),
              ),
            ],
            // テーブルデータ部分
            ...TABLE_ROW_TYPES.map((facilityType): string[] => [
              facilityTypeToLabel(facilityType),
              ...ITEM_TYPES.flatMap((itemType) =>
                periods.map((period, i) => {
                  const value = data[facilityType][i][itemType];
                  return isNaN(value) ? '' : value.toString();
                }),
              ),
            ]),
          ],
        );
      } catch (error) {
        showToast('error', 'サマリーをCSVに出力できませんでした');
      } finally {
        scrim.hide();
      }
    },
  };
};
