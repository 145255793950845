import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { ChartPagination } from '@/src/components/foundations/Charts/ChartPagination';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';

import { State } from '../../../../reducer';

type Props = {
  dateUnit: State['dateUnit'];
  displayStartIndex: number;
  displayCount: number;
  totalCount: number;
  setDateUnit: (dateUnit: State['dateUnit']) => void;
  incrementDisplayStartIndex: VoidFunction;
  decrementDisplayStartIndex: VoidFunction;
};

export const Control: React.FC<Props> = (props) => (
  <Section padding={'20px 24px'} width="initial">
    <Cluster
      align="center"
      gap={24}
      justify="flex-end"
      wrap="nowrap"
      width="initial"
    >
      <SelectDateUnit
        options={['halfYear', 'year']}
        selectedUnit={props.dateUnit}
        onClickUnit={props.setDateUnit}
      />
      <ChartPagination
        // 通常のページネーションと違い "<"/">" を押すとグラフの表示範囲を1つ左/右にずらす挙動としていることに注意。
        // ここで当コンポーネントのpropsとページネーションで期待するpropsを以下のように変換して適合させている。
        //
        // |---------totalCount-------|
        // |---displayStartIndex 0--|.|
        // ||---displayStartIndex 1--||
        // |.|---displayStartIndex 2--|
        // ==== ↑これを↓のように変換している ====
        // |--------page 1----------|.|（ページは1始まり）
        // ||--------page 2----------||
        // |.|--------page 3----------|（totalPage = 3）
        currentPage={props.displayStartIndex + 1}
        totalPage={
          props.totalCount < props.displayCount
            ? 1
            : props.totalCount + 1 - props.displayCount
        }
        nextPage={props.incrementDisplayStartIndex}
        prevPage={props.decrementDisplayStartIndex}
      />
    </Cluster>
  </Section>
);
