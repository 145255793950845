export const FEATURE_FLAG_ENV_MAP = {
  FEATURE_FLAG_AREA_REPORT_BULK_ADD:
    process.env.FEATURE_FLAG_AREA_REPORT_BULK_ADD,
  FEATURE_FLAG_COMMUNICATION_REPORT_BULK_ADD:
    process.env.FEATURE_FLAG_COMMUNICATION_REPORT_BULK_ADD,
  FEATURE_FLAG_DATA_ANALYSIS_SUMMARY:
    process.env.FEATURE_FLAG_DATA_ANALYSIS_SUMMARY,
} as const;

export const isFeatureFlagEnabled = (key: keyof typeof FEATURE_FLAG_ENV_MAP) =>
  FEATURE_FLAG_ENV_MAP[key] === 'true';
