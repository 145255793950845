import { dataAnalysisApi, referralsApi } from '@/src/api';
import {
  PostDataAnalysisRankingReferralTotalCountResponse as SearchResult,
  PostDataAnalysisRankingReferralTotalCountRequestSortEnum as SearchSort,
  PostDataAnalysisRankingReferralTotalCountRequest as SearchRequest,
} from '@/src/api/generated';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAccount } from '@/src/hooks/useAccount';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';
import {
  fromFiscalYearStartToToday,
  fromFiscalYearStartToTargetDate,
} from '@/src/utils/date/fromFiscalYearStartToToday';
import { formatDate } from '@/src/utils/formatDate';

import { SearchFormInputMap } from '../SearchForm/presenter';
import { checkIsSubmitDisabled } from './logic/checkIsSubmitDisabled';
import { TotalCountRankingContainerPresenter } from './presenter';

import { useState, useEffect } from 'react';

type SearchParams = SearchRequest;
type DateRangeProps = React.ComponentProps<typeof MonthRangePicker>;
type DateRangeOnSubmit = DateRangeProps['onChange'];

export const TotalCountRankingContainer: React.FC = () => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const { account } = useAccount();

  const INITIAL_CURRENT_PAGE = 1;
  const INITIAL_FACILITY_TYPE_IDS: SearchRequest['facilityTypeIds'] = [];
  const INITIAL_DEPARTMENT_IDS: SearchRequest['departmentIds'] = [];
  const INITIAL_SORT = SearchSort.Desc;

  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: INITIAL_CURRENT_PAGE,
    // 最新紹介日の取得まで時間がかかるため、初期値は最新の年度の初日から今日までとする
    period: {
      startDate: formatDate(fromFiscalYearStartToToday().startDate),
      endDate: formatDate(fromFiscalYearStartToToday().endDate),
    },
    prefecture: account.tenant?.prefecture ?? '',
    area: '',
    facilityTypeIds: INITIAL_FACILITY_TYPE_IDS,
    departmentIds: INITIAL_DEPARTMENT_IDS,
    sort: INITIAL_SORT,
  });

  const throwFetchError = useFetchError();

  const form = useFormWrapper<SearchFormInputMap>({
    defaultValues: {
      prefecture: account.tenant?.prefecture ?? '',
      area: '',
    },
  });

  const inputMap = form.watch();

  const totalCountReferralRankingRequest = async () => {
    try {
      setSearchResult(null);

      const response =
        await dataAnalysisApi.postDataAnalysisRankingReferralTotalCount({
          postDataAnalysisRankingReferralTotalCountRequest: searchParams,
        });

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  const initializePeriodParam = async () => {
    try {
      const lastReferralDate = (await referralsApi.getLastReferral())
        ?.referralDate;

      if (lastReferralDate) {
        const fiscalYearStart = fromFiscalYearStartToTargetDate(
          new Date(lastReferralDate),
        ).startDate;

        setSearchParams({
          ...searchParams,
          period: {
            startDate: formatDate(fiscalYearStart),
            endDate: formatDate(new Date(lastReferralDate)),
          },
        });
      }
      // 最新紹介がない場合(lastReferralDate === null)は、最新の年度の初日から今日までとするが、初期値にしているため特になにかする必要はない
    } catch (error) {
      throwFetchError(500);
    }
  };
  useEffect(() => {
    initializePeriodParam();
  }, []);

  useEffect(() => {
    totalCountReferralRankingRequest();
  }, [searchParams]);

  const onChangeSortType = () => {
    setSearchParams({
      ...searchParams,
      sort:
        searchParams.sort === SearchSort.Desc
          ? SearchSort.Asc
          : SearchSort.Desc,
      page: INITIAL_CURRENT_PAGE,
    });
  };

  const dateRangeOnSubmit: DateRangeOnSubmit = (selectedRange) => {
    setSearchParams({
      ...searchParams,
      period: {
        startDate: formatDate(selectedRange.startDate),
        endDate: formatDate(selectedRange.endDate),
      },
      page: INITIAL_CURRENT_PAGE,
    });
  };

  const setPrefecture = (value: string) => {
    form.setValue('prefecture', value);
  };

  return (
    <TotalCountRankingContainerPresenter
      rankingTable={{
        isLoading: Boolean(!searchResult),
        ranking: searchResult,
        sort: searchParams.sort,
        onChangeSortType,
      }}
      dateRange={{
        startDate: new Date(searchParams.period.startDate),
        endDate: new Date(searchParams.period.endDate),
        placement: 'bottomEnd',
        onChange: dateRangeOnSubmit,
        isInForm: true,
      }}
      searchForm={{
        form: {
          prefecture: {
            ...form.register('prefecture'),
            errorMessages: [],
            value: inputMap.prefecture,
            setValue: setPrefecture,
          },
          area: { ...form.register('area'), errorMessages: [] },
          isDisabled: checkIsSubmitDisabled(inputMap) || searchResult === null,
          onSubmit: form.handleSubmit((data) => {
            setSearchParams({
              ...searchParams,
              ...data,
              page: INITIAL_CURRENT_PAGE,
            });
          }),
        },
      }}
      searchFilter={{
        facilityTypeFilter: {
          size: 'medium',
          selectedFacilityTypeIds: searchParams.facilityTypeIds ?? [],
          onSubmit: (data) => {
            setSearchParams({
              ...searchParams,
              facilityTypeIds: data ?? [],
              page: INITIAL_CURRENT_PAGE,
            });
          },
        },
        ownDepartmentFilter: {
          size: 'medium',
          selectedOwnDepartmentIds: searchParams.departmentIds ?? [],
          onSubmit: (ownDepartmentIds) => {
            setSearchParams({
              ...searchParams,
              departmentIds: ownDepartmentIds,
              page: INITIAL_CURRENT_PAGE,
            });
          },
        },
      }}
      pagination={{
        currentPage: searchParams.page,
        lastPage: searchResult?.totalPage ?? 0,
        onClickCallback: (page: number) => {
          setSearchParams({
            ...searchParams,
            page,
          });
        },
      }}
    />
  );
};
