import type {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';

import { useAction } from './action';
import { DepartmentUnitCardPresenter } from './presenter';

import { useState, useEffect } from 'react';

type DepartmentUnitCardPresenterProps = React.ComponentProps<
  typeof DepartmentUnitCardPresenter
>;

type Props = {
  departments: GetOwnDepartmentsResponse['departments'];
};

const INITIAL_PAGE = 1;

export const DepartmentUnitCard: React.FC<Props> = (props) => {
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [referrals, setReferrals] = useState<
    PostChartReferralsComparisonDepartmentsResponse['referrals'] | null
  >(null);

  const { request } = useAction();

  useEffect(() => {
    request((res) => {
      setReferrals(res.referrals);
    });
  }, []);

  return (
    <DepartmentUnitCardPresenter
      contents={((): DepartmentUnitCardPresenterProps['contents'] => {
        if (referrals === null) {
          return {
            status: 'loading',
          };
        } else {
          return {
            status: 'normal',
            referrals,
            departments: props.departments,
            pagination: {
              currentPage,
              prevPage: () => {
                setCurrentPage((prevState) => prevState - 1);
              },
              nextPage: () => {
                setCurrentPage((prevState) => prevState + 1);
              },
            },
          };
        }
      })()}
    />
  );
};
