import styles from './styles.module.scss';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';

type Props = {
  currentPage: number;
  totalPage: number;
  prevPage: () => void;
  nextPage: () => void;
};

export const ChartPagination: React.FC<Props> = (props) => {
  return (
    <div className={styles.pagination}>
      <button
        className={clsx(
          styles.arrowLeft,
          props.currentPage === 1 && styles.inactive,
        )}
        onClick={() => props.prevPage()}
      >
        <KeyboardArrowLeftIcon className={styles.arrow} />
      </button>
      <button
        className={clsx(
          styles.arrowRight,
          props.currentPage === props.totalPage && styles.inactive,
        )}
        onClick={() => props.nextPage()}
      >
        <KeyboardArrowRightIcon className={styles.arrow} />
      </button>
    </div>
  );
};
