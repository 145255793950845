import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';
import { Pagination } from '@/src/components/foundations/Navigations/Pagination';

import { SearchFilter } from '../SearchFilter';
import { SearchForm } from '../SearchForm';
import { RankingTable } from './RankingTable';
import styles from './styles.module.scss';

type Props = {
  dateRange: React.ComponentProps<typeof MonthRangePicker>;
  searchForm: React.ComponentProps<typeof SearchForm>;
  searchFilter: React.ComponentProps<typeof SearchFilter>;
  rankingTable: React.ComponentProps<typeof RankingTable>;
  pagination: React.ComponentProps<typeof Pagination>;
};

export const TotalCountRankingContainerPresenter: React.FC<Props> = (props) => {
  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>紹介数</div>
        <div className={styles.searchFormPeriod}>
          <MonthRangePicker {...props.dateRange} />
        </div>
        <div className={styles.searchFormArea}>
          <SearchForm {...props.searchForm} />
        </div>
        <div className={styles.searchFilter}>
          <SearchFilter {...props.searchFilter} />
        </div>
      </div>
      <RankingTable {...props.rankingTable} />
      {props.pagination.lastPage !== 0 && (
        <div className={styles.paginationWrapper}>
          <Pagination {...props.pagination} />
        </div>
      )}
    </Card>
  );
};
