import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { Link } from '@/src/components/foundations/Utils/Link';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';
import { useModal } from '@/src/hooks/useModal';

type Props = {
  modal: ReturnType<typeof useModal>;
  onDismiss: VoidFunction;
};

export const ExplainModal: React.FC<Props> = (props) => (
  <ModalDialog
    size="wide"
    isShown={props.modal.isShown}
    title="連携先セグメントについて"
    okButton={{
      children: '閉じる',
      color: 'basic',
      onClick: props.onDismiss,
    }}
    cancelButton={{
      onClick: props.onDismiss,
    }}
  >
    <Stack align="stretch" gap={8}>
      <div>
        ・集計期間に応じた連携先の紹介数と紹介入院率をもとに分類しています
      </div>
      <div>
        ・閾値（紹介数・紹介入院率）や表示する診療科目の設定については担当者、もしくはサポート窓口(
        {
          <Link href={'mailto:cs@medup.jp?subject=連携先セグメントについて'}>
            cs@medup.jp
          </Link>
        }
        )までお問合せください
      </div>
    </Stack>
  </ModalDialog>
);
