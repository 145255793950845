import {
  PostDataAnalysisRankingReferralAnnualRequest as AnnualSearchRequest,
  PostDataAnalysisRankingReferralMonthlyRequest as MonthlySearchRequest,
} from '@/src/api/generated';
import { useAccount } from '@/src/hooks/useAccount';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';

import { SearchFormInputMap } from '../SearchForm/presenter';
import { checkIsSubmitDisabled } from './logic/checkIsSubmitDisabled';
import { RankingContainerPresenter } from './presenter';

type RankingContainerPresenterProps = React.ComponentProps<
  typeof RankingContainerPresenter
>;
type Props<V> = {
  rankingTable: RankingContainerPresenterProps['rankingTable'];
  searchParams: V;
  onChangeSearchParams: (newSearchParams: V) => void;
  isSearching: boolean;
  monthPicker?: RankingContainerPresenterProps['monthPicker'];
};
type MonthlySearchParams = Omit<MonthlySearchRequest, 'month'> & {
  month: Date;
};
type AnnualSearchParams = AnnualSearchRequest;

export const RankingContainer: <
  T extends MonthlySearchParams | AnnualSearchParams,
>(
  props: Props<T>,
) => JSX.Element = (props) => {
  const { account } = useAccount();

  const form = useFormWrapper<SearchFormInputMap>({
    defaultValues: {
      prefecture: account.tenant?.prefecture ?? '',
      area: '',
    },
  });

  const INITIAL_CURRENT_PAGE = 1;

  const inputMap = form.watch();

  const setPrefecture = (value: string) => {
    form.setValue('prefecture', value);
  };

  return (
    <RankingContainerPresenter
      monthPicker={props.monthPicker}
      searchForm={{
        form: {
          prefecture: {
            ...form.register('prefecture'),
            errorMessages: [],
            value: inputMap.prefecture,
            setValue: setPrefecture,
          },
          area: { ...form.register('area'), errorMessages: [] },
          isDisabled: checkIsSubmitDisabled(inputMap) || props.isSearching,
          onSubmit: form.handleSubmit((data) => {
            props.onChangeSearchParams({
              ...props.searchParams,
              ...data,
              page: INITIAL_CURRENT_PAGE,
            });
          }),
        },
      }}
      searchFilter={{
        facilityTypeFilter: {
          size: 'medium',
          selectedFacilityTypeIds: props.searchParams.facilityTypeIds ?? [],
          onSubmit: (data) => {
            props.onChangeSearchParams({
              ...props.searchParams,
              facilityTypeIds: data ?? [],
              page: INITIAL_CURRENT_PAGE,
            });
          },
        },
        ownDepartmentFilter: {
          size: 'medium',
          selectedOwnDepartmentIds: props.searchParams.departmentIds ?? [],
          onSubmit: (ownDepartmentIds) => {
            props.onChangeSearchParams({
              ...props.searchParams,
              departmentIds: ownDepartmentIds,
              page: INITIAL_CURRENT_PAGE,
            });
          },
        },
      }}
      rankingTable={props.rankingTable}
      pagination={{
        currentPage: props.searchParams.page,
        lastPage: props.rankingTable?.ranking?.totalPage ?? 0,
        onClickCallback: (page: number) => {
          props.onChangeSearchParams({
            ...props.searchParams,
            page,
          });
        },
      }}
    />
  );
};
