import { useResponsive } from './useResponsive';

import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InputMap = Record<string, any>;

export const useFormWrapper = <T extends InputMap>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseFormProps<T, Record<string, any>> = {},
): UseFormReturn<T> => {
  const form = useForm<T>({
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    ...options,
  });
  const responsive = useResponsive();

  return {
    ...form,
    handleSubmit: (submitHandler, errorHandler) =>
      form.handleSubmit(
        (params, event) => {
          responsive.isSp &&
            ((document.activeElement as HTMLElement) || null)?.blur();
          submitHandler(params, event);
        },
        (error, event) => {
          responsive.isSp &&
            ((document.activeElement as HTMLElement) || null)?.blur();
          errorHandler && errorHandler(error, event);
        },
      ),
  };
};
