import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { Loading } from '../Loading';
import { WIDE_CHART_SIZE } from '../const';
import styles from './styles.module.scss';

type ContentsLoading = {
  status: 'loading';
  dateRange: React.ComponentProps<typeof MonthRangePicker>;
  selectDateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
};
type ContentsNormal = {
  status: 'normal';
  dateRange: React.ComponentProps<typeof MonthRangePicker>;
  selectDateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
  referrals: Omit<React.ComponentProps<typeof BarChart>, 'size' | 'mode'>;
};

type Props = {
  searchContents: ContentsLoading | ContentsNormal;
};

export const AllReferralsCardPresenter: React.FC<Props> = (props) => (
  <div className={styles.card}>
    <div className={styles.header}>
      <div className={styles.title}>紹介数推移</div>
      <div className={styles.control}>
        <MonthRangePicker {...props.searchContents.dateRange} />
      </div>
    </div>
    <div className={styles.subControls}>
      <SelectDateUnit {...props.searchContents.selectDateUnit} />
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.searchContents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={WIDE_CHART_SIZE}
                {...props.searchContents.referrals}
                mode="stack"
              />
            );
        }
      })()}
    </div>
  </div>
);
