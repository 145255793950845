import { PrefectureSearchTextField } from '@/src/components/blocks/PrefectureSearchTextField';
import { FacilityTypeFilterChip } from '@/src/components/features/FilterChip/FacilityTypeFilterChip';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Input } from '@/src/components/foundations/Forms/Input';
import { MonthPicker } from '@/src/components/foundations/Forms/MonthPicker';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { UseFormReturn } from '@/src/hooks/useForm';

import { Schema } from '../../form';
import styles from './styles.module.scss';

import Search from '@mui/icons-material/Search';

type Props = {
  form: UseFormReturn<Schema>;
  onSearch: VoidFunction;
  monthPicker: Omit<
    React.ComponentProps<typeof MonthPicker>,
    'placement' | 'onClean'
  >;
  facilityTypeFilter: Omit<
    React.ComponentProps<typeof FacilityTypeFilterChip>,
    'size'
  >;
  ownDepartmentFilter: Omit<
    React.ComponentProps<typeof OwnDepartmentFilterChip>,
    'size'
  >;
};

export const Header: React.FC<Props> = (props) => (
  <Section padding="24px">
    <Stack gap="16px" align="flex-start">
      <Cluster align="center" justify="space-between" width="100%">
        <div className={styles.title}>医療機関ごとの紹介数との対比</div>
        <MonthPicker {...props.monthPicker} placement="bottomEnd" isInForm />
      </Cluster>
      <Cluster gap="16px" align="center">
        <div className={styles.prefecture}>
          <PrefectureSearchTextField
            optionIdPrefix="prefecture"
            mode="normal"
            {...props.form.register('prefecture')}
            value={props.form.watch('prefecture') ?? ''}
            setValue={(value) => {
              props.form.setValue('prefecture', value);
            }}
          />
        </div>
        <div className={styles.area}>
          <Input
            placeholder="地域"
            type="text"
            {...props.form.register('area')}
          />
        </div>
        <Button
          color="primary"
          icon={{ body: <Search />, position: 'left' }}
          onClick={props.onSearch}
        >
          検索
        </Button>
      </Cluster>
      <Cluster gap="8px" align="center">
        <FacilityTypeFilterChip {...props.facilityTypeFilter} size="medium" />
        <OwnDepartmentFilterChip {...props.ownDepartmentFilter} size="medium" />
      </Cluster>
    </Stack>
  </Section>
);
